*{
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'IBM Plex Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: radial-gradient(25% 25% at top center,rgba(255, 255, 255, 0.66),#000000); */
  background-color: #000;
  color: white;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} 

  
.divider {								/* minor cosmetics */
  display: table; 
  font-size: 24px; 
  text-align: center; 
  width: 75%; 						/* divider width */
  margin: 40px auto;					/* spacing above/below */
}
.divider span { display: table-cell; position: relative; }
.divider span:first-child, .divider span:last-child {
  width: 50%;
  top: 13px;							/* adjust vertical align */
  -moz-background-size: 100% 2px; 	/* line width */
  background-size: 100% 2px; 			/* line width */
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
}
.divider span:first-child {				/* color changes in here */
  background-image: linear-gradient(linear, 0 0, 0 100%, from(transparent), to(rgb(255, 255, 255)));
  background-image: -webkit-linear-gradient(180deg, transparent, rgb(255, 255, 255));
  background-image: -moz-linear-gradient(180deg, transparent, rgb(255, 255, 255));
  background-image: -o-linear-gradient(180deg, transparent, rgb(255, 255, 255));
  background-image: linear-gradient(90deg, transparent, rgb(255, 255, 255));
}
.divider span:nth-child(2) {
  color: rgb(255, 255, 255); padding: 0px 5px; width: auto; white-space: nowrap;
}
.divider span:last-child {				/* color changes in here */
  background-image: linear-gradient(linear, 0 0, 0 100%, from(rgb(255, 255, 255)), to(transparent));
  background-image: -webkit-linear-gradient(180deg, rgb(255, 255, 255), transparent);
  background-image: -moz-linear-gradient(180deg, rgb(255, 255, 255), transparent);
  background-image: -o-linear-gradient(180deg, #000, transparent);
  background-image: linear-gradient(90deg, rgb(255, 255, 255), transparent);
}